<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="1" title="Workflow Step" icon="" />

    <div class="generalBorderlessBox">
        <div class="row">
            <div class="col-lg-5">
                <div class="generalContentBox border shadow-sm">

                    <div class="row">
                        <!-- <div class="col-12 mb-2 bg-warning">{{arrItem.length}} | {{arrItem}}</div> -->
                        <!-- <div class="col-12 mb-2 bg-info">arrNodeLevel | {{arrNodeLevel.length}} | {{arrNodeLevel}}</div> -->
                        
                        <div class="col-lg-12 pb-2 fw-bold">Design your workflow by add steps accordingly</div>

                        <div class="col-lg-8 pb-2">
                            Change Request - Acc Mgr
                        </div>
                        <div class="col-lg-4 pb-3 text-end">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#mdlAddStep" @click="isSubLevel = false">
                                <fa icon="plus" class="me-2 text-primary" /><span class="text-primary">Add step</span>
                            </a>
                        </div>

                        <div class="col-12 mb-2">
                            <table class="table table-hover">
                                <tbody>
                                    <tr v-for="(item, itemIdx) in arrItem" :key="item">
                                        <td width="65px">STEP {{itemIdx+1}}</td>
                                        <td>
                                            <div v-for="(i, iIdx) in item" :key="i">
                                                <!-- One level only-->
                                                <table v-if="iIdx === 0" width="100%">
                                                    <tr>
                                                        <td>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <span v-if="i.type === 'signee' && i.signeeId === 'Initiator'"><fa icon="user-pen" size="lg" class="me-2" /></span>
                                                                    <span v-if="i.type === 'signee' && i.signeeId === 'External'"><fa icon="user-slash" size="lg" class="me-2" /></span>
                                                                    <span v-if="i.type === 'team'"><fa icon="users" size="lg" class="me-2" /></span>
                                                                    <span v-if="i.type === 'member'"><fa icon="user" size="lg" class="me-3" /></span>
                                                                    {{i.name}}
                                                                </div>
                                                                <!-- Small screen -->
                                                                <div class="col-12 d-md-none text-end mt-1 mb-2">
                                                                    <span>
                                                                        <Popper class="popperDark" arrow hover content="Delete step">
                                                                            <fa icon="trash" class="text-secondary me-4 isLink" @click="delStep(itemIdx, iIdx, i.node)" />
                                                                        </Popper>
                                                                    </span> 

                                                                    <span class="text-grey me-4 isLink" @click="selectConn(i)" data-bs-toggle="modal" data-bs-target="#mdlAddConnector">
                                                                        <Popper class="popperDark" arrow hover content="Set connector">
                                                                            <fa icon="share-nodes" size="lg" />
                                                                        </Popper>
                                                                    </span>
                                                                    
                                                                    <span @click="isSubLevel = true; upperLevel = itemIdx" data-bs-toggle="modal" data-bs-target="#mdlAddStep">
                                                                        <Popper class="popperDark" arrow hover content="Add more role/signee in this level">
                                                                            <fa icon="user-plus" class="text-secondary me-2 isLink" />
                                                                        </Popper>
                                                                    </span>
                                                                </div>
                                                                <!-- Small screen end-->
                                                            </div>
                                                        </td>
                                                        <td class="text-end hoverShow">
                                                            <div class="d-none d-md-block">
                                                                <span class="text-grey isLink me-4" data-bs-toggle="modal" data-bs-target="#mdlEscalation">
                                                                    <Popper class="popperDark" arrow hover content="Auto escalation">
                                                                        <fa icon="fire" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span v-if="iIdx === item.length-1" class="text-grey me-4 isLink" :class="iIdx !== 0 ? 'pb-2' : ''" @click="isSubLevel = true; upperLevel = itemIdx" data-bs-toggle="modal" data-bs-target="#mdlAddStep">
                                                                    <Popper class="popperDark" arrow hover content="Add more role/signee in this level">
                                                                        <fa icon="user-plus" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span v-if="arrItem && arrItem.length > 1" class="text-grey me-4 isLink" @click="selectConn(i)" data-bs-toggle="modal" data-bs-target="#mdlAddConnector">
                                                                    <Popper class="popperDark" arrow hover content="Set connector">
                                                                        <fa icon="share-nodes" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span class="text-grey isLink me-2" @click="delStep(itemIdx, iIdx, i.node)">
                                                                    <Popper class="popperDark" arrow hover content="Delete step">
                                                                        <fa icon="trash" size="lg" />
                                                                    </Popper>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- Multiple level / OR -->
                                                <table v-if="iIdx !== 0" width="100%">
                                                    <tr>
                                                        <td colSpan="2" class="text-grey">OR</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div :class="i.isRole ? 'badge bg-grey' : ''">
                                                                <span v-if="i.type === 'signee' && i.signeeId === 'Initiator'"><fa icon="user-pen" size="lg" class="me-2" /></span>
                                                                <span v-if="i.type === 'signee' && i.signeeId === 'External'"><fa icon="user-slash" size="lg" class="me-2" /></span>
                                                                <span v-if="i.type === 'team'"><fa icon="users" size="lg" class="me-2" /></span>
                                                                <span v-if="i.type === 'member'"><fa icon="user" size="lg" class="me-3" /></span>
                                                                {{i.name}}    
                                                            </div>
                                                            <div class="d-md-none text-end mt-1 mb-2">
                                                                <span>
                                                                    <Popper class="popperDark" arrow hover content="Delete step">
                                                                        <fa icon="trash" class="text-secondary me-4 isLink" @click="delStep(itemIdx, iIdx, i.node)" />
                                                                    </Popper>
                                                                </span> 

                                                                <span class="text-grey me-4 isLink" @click="selectConn(i)" data-bs-toggle="modal" data-bs-target="#mdlAddConnector">
                                                                    <Popper class="popperDark" arrow hover content="Set connector">
                                                                        <fa icon="share-nodes" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span class="text-grey isLink me-4" data-bs-toggle="modal" data-bs-target="#mdlEscalation">
                                                                    <Popper class="popperDark" arrow hover content="Auto escalation">
                                                                        <fa icon="fire" size="lg" />
                                                                    </Popper>
                                                                </span>
                                                                
                                                                <span @click="isSubLevel = true; upperLevel = itemIdx" data-bs-toggle="modal" data-bs-target="#mdlAddStep">
                                                                    <Popper class="popperDark" arrow hover content="Add more role/signee in this level">
                                                                        <fa icon="user-plus" class="text-secondary me-2 isLink" />
                                                                    </Popper>
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td class="text-end hoverShow">
                                                            <div class="d-none d-md-block">
                                                                <span v-if="iIdx === item.length-1" class="text-grey me-4 isLink" :class="iIdx !== 0 ? 'pb-2' : ''" @click="isSubLevel = true; upperLevel = itemIdx" data-bs-toggle="modal" data-bs-target="#mdlAddStep">
                                                                    <Popper class="popperDark" arrow hover content="Add more role/signee in this level">
                                                                        <fa icon="user-plus" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span v-if="arrItem && arrItem.length > 1" class="text-grey me-4 isLink" @click="selectConn(i)" data-bs-toggle="modal" data-bs-target="#mdlAddConnector">
                                                                    <Popper class="popperDark" arrow hover content="Set connector">
                                                                        <fa icon="share-nodes" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span class="text-grey isLink me-4" data-bs-toggle="modal" data-bs-target="#mdlEscalation">
                                                                    <Popper class="popperDark" arrow hover content="Auto escalation">
                                                                        <fa icon="fire" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span class="text-grey isLink me-2" @click="delStep(itemIdx, iIdx, i.node)">
                                                                    <Popper class="popperDark" arrow hover content="Delete step">
                                                                        <fa icon="trash" size="lg" />
                                                                    </Popper>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- Name & feature End -->
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                           
                        </div>
                        
                    </div>

                </div>
            </div>

            <div class="col-lg-7">
                <div class="generalContentBox" :style="'height: ' + diaHeight + 'px'" :class="arrItem && arrItem.length > 0 ? 'border shadow-sm' : ''">
                    <v-network-graph
                        :nodes="diaNodes"
                        :edges="diaEdges"
                        :layouts="diaLayouts"
                        :configs="diaConfigs" 
                        :paths="diaPaths" 
                        class="myGraph" />
                </div>
            </div>

            <div class="col-12 col-lg-4">
                <button class="btn btn-outline-secondary me-2 mb-4" @click="$router.push({ path: '/workflow' })"><fa icon="circle-arrow-left" size="lg" class="me-2" />Back</button>
            </div>
            <div class="col-12 col-lg-8 text-end mb-4">
                <button v-if="arrItem && arrItem.length >= 1" class="btn btn-outline-secondary me-2">
                    <fa icon="trash" size="lg" class="me-2" />Delete
                </button>
                <!-- <button v-if="arrItem && arrItem.length >= 2" class="btn btn-secondary me-2">
                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                </button> -->
                <button v-if="arrItem && arrItem.length >= 1" @click="$router.push({ path: '/workflow/doc' })" class="btn btn-secondary me-2">
                    <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                </button>
            </div>
        </div>
    </div>

    <!-- Modal: mdlEscalation --> 
    <div
        class="modal fade"
        id="mdlEscalation"
        tabindex="-1"
        aria-labelledby="AutoEscalation"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Auto Escalation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <table>
                        <tr>
                            <td class="align-top pe-2">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" v-model="autoEscalation" value="true" checked>
                                </div>
                            </td>
                            <td>
                                <div class="mb-2">Auto escalation</div>
                                <div v-if="autoEscalation">
                                    <div class="mb-2">Auto send to</div>
                                    <div class="mb-3">
                                        <select class="form-select">
                                            <option value="">Select member</option>
                                            <option :value="m.userId" v-for="m in arrMember" :key="m">{{m.displayName}}</option>
                                        </select>
                                    </div>
                                    <div class="mb-2">If no action taken after</div>
                                    <div class="mb-2">
                                        <div class="row">
                                            <div class="col-6"><input type="textbox" class="form-control" /></div>
                                            <div class="col-6">
                                                <select class="form-select">
                                                    <option value="day">Day</option>
                                                    <option value="hour">Hour</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal: mdlAddStep --> 
    <div
        class="modal fade"
        id="mdlAddStep"
        tabindex="-1"
        aria-labelledby="AddStep"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add Step</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-12 mb-3">
                            <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="pill" href="#team">Team</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="pill" href="#member">Member</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="pill" href="#signee">Signee</a>
                                </li>
                            </ul>

                            <div class="tab-content mt-4">
                                <div class="tab-pane container active" id="team">
                                    <select v-model="team" class="form-select">
                                        <option value="null">Select team</option>
                                        <option :value="t.teamId" v-for="t in arrTeam" :key="t">{{t.teamName}}</option>
                                    </select>
                                </div>

                                <div class="tab-pane container fade" id="member">
                                    <select v-model="member" class="form-select">
                                        <option value="null">Select member</option>
                                        <option :value="m.userId" v-for="m in arrMember" :key="m">{{m.displayName}}</option>
                                    </select>
                                </div>

                                <div class="tab-pane container fade" id="signee">
                                    <select v-model="signee" class="form-select">
                                        <option value="null">Select signee</option>
                                        <option value="Initiator">Initiator</option>
                                        <option value="External">External</option>
                                    </select>
                                </div>

                                <!-- <div class="tab-pane container active" id="role">
                                    <select v-model="role" class="form-select">
                                        <option value="null">Select role</option>
                                        <option :value="r" v-for="r in arrRole" :key="r">{{r}}</option>
                                    </select>
                                </div>

                                <div class="tab-pane container fade" id="signee">
                                    <select v-model="signee" class="form-select">
                                        <option value="null">Select signee</option>
                                        <option :value="s.signeeId" v-for="s in arrSignee" :key="s.signeeId">{{s.name}} ({{s.email}})</option>
                                    </select>
                                </div> -->
                            </div>

                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="signee = null; role = null">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="team !== null || member !== null || signee !== null" type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="totalNode++; addStep()">
                        <fa icon="plus" class="me-2" />Add
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal: mdlAddConnector --> 
    <div
        class="modal fade"
        id="mdlAddConnector"
        tabindex="-1"
        aria-labelledby="AddConnector"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> {{connNodeLabel}} :: Add connection</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body text-grey">
                    <div class="row">
                        <div class="col-1 align-top fw-bold">To</div>
                        <div class="col-11">
                            <div v-for="(item, itemIdx) in arrItem" :key="item" class="p-0">
                                <div v-for="i in item" :key="i" class="m-0 p-0" >
                                    <table v-if="i.node !== connNode" class="table table-hover m-0">
                                        <tbody>
                                            <tr>
                                                <td width="10%"><input class="form-check-input me-2" type="checkbox" v-model="connTarget" :value="i.node" ></td>
                                                <td width="20%">STEP {{itemIdx+1}}</td>
                                                <td width="70%">{{i.name}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="setEdges">
                        <fa icon="plus" class="me-2" />Add
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
</template>

<script>
import { ref, inject, onMounted, watch, reactive } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'

import { VNetworkGraph } from 'v-network-graph'
import * as vNG from 'v-network-graph'
import "v-network-graph/lib/style.css"

export default {
    name: 'AddWorkFlow',
    components: { TopNavigation, Alert, Popper, VNetworkGraph },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const arrTeam = ref([])
        const arrMember = ref([])
        const arrSignee = ref([])
        const arrItem = ref([])

        const isSubLevel = ref(false)
        const upperLevel = ref(0)
        const step = ref(0)
        const subStep = ref(0)
        const autoEscalation = ref(false)
        const escalationAction = ref('reject')
        const escalationSignee = ref(null)

        const originX = ref(0) // 137
        const originY = ref(-300)
        const totalNode = ref(0)
        const nodeXGap = ref(130) 
        const nodeYGap = ref(70)
        const arrNodeLevel = ref([]) // level of node
        const arrNode = ref([])
        const arrEdge = ref([])
        const diaHeight = ref(550)

        const connNode = ref(null)
        const connNodeLabel = ref(null)
        const connTarget = ref([])

        const diaLayouts = ref([])
        const diaPaths = ref([])
        const diaNodes = ref({})
        const diaEdges = ref({})
        
        const team = ref(null) // add step
        const member = ref(null) // add step
        const signee = ref(null) // add step
        const selectedTeam = ref({})
        const selectedMember = ref({})
        const selectedSignee = ref({})

        const diaConfigs = reactive(
            vNG.defineConfigs({
                view: {
                    panEnabled: false,
                    zoomEnabled: false,
                    doubleClickZoomEnabled: false,
                },
                node: {
                    draggable: false,
                    normal: { 
                        radius: 20,
                        strokeWidth: 2, 
                        color: '#53BDEB',
                        strokeColor: ''
                    },
                    label: {
                        color: '#6E777E',
                    },
                    /* hover: {
                        radius: 22,
                        color: '#53BDEB',
                    }, */
                    
                },
                edge: {
                    gap: 20,
                    type: "curve",
                    // margin: 6,
                    margin: 0,
                    normal: {
                        color: "#dee2e6",
                        // width: 2,
                        width: 1,
                    },
                    hover: {
                        width: 2,
                        color: '#dee2e6',
                    },
                    marker: {
                        target: {
                            type: "arrow",
                            width: 8,
                            height: 8,
                        }
                    }
                },
                path: {
                    visible: true,
                    normal: {
                        width: 10,
                        color: "#53BDEB80",
                        dasharray: "10 16",
                        animate: true,
                        animationSpeed: 40,
                    },
                }
            })
        )

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([team, member], () => {
            console.info('- - watch: team/member', team.value, member.value)
            // console.info('watch select signee', signee.value)
            /* if (signee.value !== null) {
                arrSignee.value.forEach((item) => {
                    if (item.signeeId === signee.value) {
                        selectedSignee.value = item
                    }
                })
            } */

            if (team.value !== null) {
                arrTeam.value.forEach((item) => {
                    if (item.teamId === team.value) {
                        selectedTeam.value = item
                    }
                })
                
            }

            if (member.value !== null) {
                arrMember.value.forEach((item) => {
                    if (item.userId === member.value) {
                        selectedMember.value = item
                    }
                })
            }
        })

        const selectConn = (data) => {
            connNode.value = data.node
            connNodeLabel.value = data.name
            connTarget.value = []

            console.info('select', connNode.value)

            // connTarget.value 
            arrEdge.value.forEach((i, iIdx) => {
                if (i.source === connNode.value) {
                    connTarget.value.push(i.target)
                }
            })
        }

        watch([arrItem.value, arrNode.value], () => {
            // console.info('----WATCH: arrItem', JSON.stringify(arrItem.value))
            genDiagram()
        })

        const genDiagram = async () => {
            console.info('genDiagram')

            diaNodes.value = {}

            arrNode.value.forEach((n, nIdx) => {
                diaNodes.value[n.node] = { name: n.label, icon: '&#xe320'}
            })
            sortNodeLayout()
        }

        const addStep = async () => {
            
            let lblName = ''

            if (isSubLevel.value) {
                // console.info('add-1', team.value, member.value)
                // Add more role/signee in same level
                if (signee.value !== null) {
                    lblName = signee.value
                    arrItem.value[upperLevel.value].push({type: 'signee', signeeId: signee.value, name: signee.value, email: '', node: 'node' + totalNode.value})

                } else if (member.value !== null) {
                    lblName = selectedMember.value.displayName
                    arrItem.value[upperLevel.value].push({type: 'member', signeeId: selectedMember.value.userId, name: selectedMember.value.displayName, email: selectedMember.value.userId, node: 'node' + totalNode.value})
                
                } else if (team.value !== null) {
                    lblName = selectedTeam.value.teamName
                    arrItem.value[upperLevel.value].push({type: 'team', signeeId: selectedTeam.value.teamId, name: selectedTeam.value.teamName, email: '', node: 'node' + totalNode.value})
                
                }

                arrNodeLevel.value[upperLevel.value].push('node' + totalNode.value)
                arrNode.value.push({ node: 'node' + totalNode.value, label: lblName})

            } else {
                // console.info('add-2', 'step', step.value, '|', team.value, member.value, signee.value)
                arrItem.value.push([])

                if (signee.value !== null) {
                    lblName = signee.value
                    arrItem.value[step.value].push({type: 'signee', signeeId: signee.value, name: signee.value, email: '', node: 'node' + totalNode.value})

                } else if (team.value !== null) {
                    lblName = selectedTeam.value.teamName
                    arrItem.value[step.value].push({type: 'team', signeeId: selectedTeam.value.teamId, name: selectedTeam.value.teamName, email: '', node: 'node' + totalNode.value})
                
                } else if (member.value !== null) {
                    lblName = selectedMember.value.displayName
                    arrItem.value[step.value].push({type: 'member', signeeId: selectedMember.value.userId, name: selectedMember.value.displayName, email: selectedMember.value.userId, node: 'node' + totalNode.value})
                
                }

                step.value = arrItem.value.length

                if (arrNodeLevel.value.length > 6) {
                    let addLevel = arrNodeLevel.value.length - 6

                    diaHeight.value = 600 + (addLevel * 120)
                    originY.value = -300 - (addLevel * 60)
                }

                let curLevel = arrNodeLevel.value.length
                arrNodeLevel.value[curLevel] = []
                arrNodeLevel.value[curLevel].push("node" + totalNode.value)

                arrNode.value.push({ node: 'node' + totalNode.value, label: lblName})
            }

            team.value = null
            member.value = null
            signee.value = null

            genDiagram()
        }

        const sortNodeLayout = async () => {

            diaLayouts.value = {}
            diaLayouts.value["nodes"] = {}

            if (arrNodeLevel.value) {

                let x = originX.value
                let y = originY.value

                arrNodeLevel.value.forEach((item, itemLevel) => {

                    if (item.length === 1) {
                       
                        x = originX.value
                        y = y + nodeYGap.value
                        diaLayouts.value["nodes"][item] = { x: x, y: y}

                    } else {

                        y = y + nodeYGap.value

                        for(var i = 0; i < item.length; i++)
                        {
                            if (item.length === 2) {
                               if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 60), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 60), y: y}
                               }
                            }

                            if (item.length === 3) {
                               if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 120), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: originX.value, y: y}
                               }
                               if (i === 2) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 120), y: y}
                               }
                            }

                            if (item.length === 4) {
                               if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 180), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 60), y: y}
                               }
                               if (i === 2) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 60), y: y}
                               }
                               if (i === 3) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 180), y: y}
                               }
                            }

                            if (item.length === 5) {
                                if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 240), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 120), y: y}
                               }
                               if (i === 2) {
                                    diaLayouts.value["nodes"][item[i]] = { x: originX.value, y: y}
                               }
                               if (i === 3) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 120), y: y}
                               }
                               if (i === 4) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 240), y: y}
                               }
                            }

                            if (item.length === 6) {
                                if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 300), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 180), y: y}
                               }
                               if (i === 2) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 60), y: y}
                               }
                               if (i === 3) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 60), y: y}
                               }
                               if (i === 4) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 180), y: y}
                               }
                               if (i === 5) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 300), y: y}
                               }
                            }
                        }
                    }
                    
                })
            } else {
                console.info('x node')
            }
        }

        const setEdges = async () => {
            console.info('setEdges target', JSON.stringify(connTarget.value))

            if (arrEdge.value) {
                let isNew = true

                // Delete old record
                arrEdge.value.forEach((i, iIdx) => {
                    if (i.source === connNode.value) {
                        arrEdge.value.splice(iIdx, 1)
                    }
                })

                // Update new record
                arrEdge.value.forEach((i, iIdx) => {
                    if (i.source === connNode.value) {
                        isNew = false
                        connTarget.value.forEach((t) => {
                            arrEdge.value[iIdx] = { source: connNode.value, target: t}
                        })
                    }
                })

                if(isNew) {
                    connTarget.value.forEach((t) => {
                        // arrEdge.value.push({ source: connNode.value, target: connTarget.value}) // working
                        arrEdge.value.push({ source: connNode.value, target: t}) // working
                    })
                    
                }
                
                // generate diaEdges
                arrEdge.value.forEach((i, iIdx) => {
                    let edgeNo = iIdx + 1
                    diaEdges.value["edge" + edgeNo] = { source: i.source, target: i.target }
                })
            }
        }

        const delStep = (idx1, idx2, node2) => {
            console.info('delStep', node2)

            if (arrItem.value[idx1].length === 1) {
                arrItem.value.splice(idx1, 1)
            } 
            else 
            {
                arrItem.value[idx1].splice(idx2, 1)
            }

            step.value = arrItem.value.length


            arrNode.value.forEach((i, iIdx) => {
                if (i.node === node2) {
                    arrNode.value.splice(iIdx, 1)
                }
            })

            arrNodeLevel.value.forEach((l, lIdx) => {
                l.forEach((sl, slIdx) => {
                    if (sl === node2) {

                        if (arrNodeLevel.value[lIdx].length === 1) {
                            arrNodeLevel.value.splice(lIdx, 1)

                        } else {
                            arrNodeLevel.value[lIdx].splice(slIdx, 1)
                        }
                    }
                })
               
            })

            genDiagram()
        }

        const getTeam = () => {
            axios.post('/entity/team/list', {})
                .then((res) => {
                    

                    if (res.data.status === 1) {
                        arrTeam.value = res.data.data
                        // console.info('getTeam', JSON.stringify(res.data.data))

                    } else {
                        func.addLog('workflowCreate', 'getTeam', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('workflowCreate', 'getTeam - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const getMember = () => {
            axios.post('/entity/member/list', {})
                .then((res) => {
                    

                    if (res.data.status === 1) {
                        arrMember.value = res.data.data
                        // console.info('getMember', JSON.stringify(res.data.data))

                    } else {
                        func.addLog('workflowCreate', 'getMember', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('workflowCreate', 'getMember - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        /* const getSignee = () => {
            arrSignee.value = [
                {
                    "signeeId": "6E7A09F8E24E11EC89D6FB54B2806B93",
                    "email": "arshad@genusis.com",
                    "name": "Arshad",
                    "role": "Support",
                    "isUser": false
                },
                {
                    "signeeId": "6A831A38E24E11EC89D6FB54B2806B94",
                    "email": "greeny.yeoh@genusis.com",
                    "name": "Greeny Yeoh Lay Ching",
                    "role": "CS",
                    "isUser": false
                },
                {
                    "signeeId": "DED17C76E24F11EC89D6FB54B2801234",
                    "email": "enerson.yap@genusis.com",
                    "name": "Enerson",
                    "role": "Director",
                    "avatar": "https://lh3.googleusercontent.com/a/AATXAJy75Od2bWhR_ZCFPKNSYe9HMOHENV6QEJcMGd1K=s96-c",
                    "isUser": true
                },
                {
                    "signeeId": "DED17C76E24F11EC89D6FB54B2806B98",
                    "email": "yennee.lim@genusis.com",
                    "name": "Lim Yen Nee",
                    "role": "Developer",
                    "avatar": "https://lh3.googleusercontent.com/a/AATXAJy75Od2bWhR_ZCFPKNSYe9HMOHENV6QEJcMGd1K=s96-c",
                    "isUser": true
                },
                {
                    "signeeId": "E3568F3EE24F11EC89D6FB54B2806B95",
                    "email": "yunghee.lee@genusis.com",
                    "name": "Lee Yung Hee",
                    "role": "Developer",
                    "isUser": true
                },
                
            ]
        } */

        const genDiagram_ref = () => {

            /* diaPaths.value = {
                path1: {
                    edges: ["edge1", "edge3", "edge6"]
                }
            } */

            diaNodes.value = {
                node1: { name: "Node 1", icon: "&#xe320" },
                node2: { name: "Node 2", icon: "&#xe328" },
                node3: { name: "Node 3", icon: "&#xe328" },
                node4: { name: "Node 4", icon: "&#xe328" },
                node5: { name: "Node 5 Customer Service", icon: "&#xe328" },
                node6: { name: "Node 6", icon: "&#xe328" },
                node7: { name: "Node 7", icon: "&#xe328" },
                node8: { name: "Node 8", icon: "&#xe328" },
                node9: { name: "Node 9", icon: "&#xe328" },
                node10: { name: "Node 10", icon: "&#xe328" },
                node11: { name: "Node 11", icon: "&#xe328" },
            }

            diaEdges.value = {
                edge1: { source: "node1", target: "node2" },
                edge2: { source: "node2", target: "node3" },
                edge3: { source: "node2", target: "node4" },
                edge4: { source: "node2", target: "node5" },
                edge5: { source: "node3", target: "node6" },
                edge6: { source: "node4", target: "node6" },
                edge7: { source: "node5", target: "node7" },
                edge8: { source: "node6", target: "node8" },
                edge9: { source: "node7", target: "node8" },
                edge10: { source: "node8", target: "node9" },
                edge11: { source: "node9", target: "node10" },
                edge12: { source: "node10", target: "node11" },
            }


            diaLayouts.value = {
                nodes: {
                    node1: { x: 137, y: 0 },
                    node2: { x: 137, y: 70 },
                    node3: { x: 17, y: 140 },
                    node4: { x: 137, y: 140 },
                    node5: { x: 257, y: 140 },
                    node6: { x: 120, y: 210 },
                    node7: { x: 250, y: 210 },
                    node8: { x: 137, y: 280 },
                    node9: { x: 137, y: 350 },
                    node10: { x: 137, y: 420 },
                    node11: { x: 137, y: 490 },
                }
            }

            showDiagram.value = true
        }

        onMounted(() => {
            getTeam()
            getMember()
            // getSignee()

            // genDiagram()
            
        })

        return { 
            route, router, alert, closeAlert, 
            arrTeam, arrMember, arrSignee, arrItem, step, subStep, isSubLevel, upperLevel, autoEscalation, escalationAction, escalationSignee, 
            totalNode, 
            team, member, signee, selectedTeam, selectedMember, selectedSignee, addStep, delStep, diaHeight, 
            genDiagram, diaConfigs, 
            diaLayouts, diaPaths, diaNodes, diaEdges, 
            arrNodeLevel, connNode, connNodeLabel, connTarget, setEdges, arrNode, arrEdge, selectConn,

            // diaLayouts, diaPaths, diaNodes, diaEdges, 
        }

    }
}
</script>

<style>
.myGraph {
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.table-hover > tbody > tr .hoverShow {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .hoverShow {
    visibility: visible;
}
</style>